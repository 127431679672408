@import "../../../../scss/mixins";
@import "../../../../scss/variables";

.pricePage {

    .pricingContainer {
        padding: 0 5% 5% 5%;

        h2 {
            font-size: 46px;
            line-height: 41px;
            font-weight: 300;
        }

        p {
            font-size: 24px;
            line-height: 28px;
            padding-right: 30%;
            color: $color-light-grey;
            font-weight: 300;
        }

        .plans {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(320px, $price-card-max-width));
            grid-template-rows: repeat(auto-fill, minmax($price-card-min-width, $price-card-max-width));
            grid-auto-flow: dense;
            grid-gap: 20px;
            width: 80%;
            margin: 0 auto;
            margin-top: 5rem;

            .cardContainer {
                border-radius: 5px;
                background-color: #fafafa;
                box-sizing: border-box;
                -webkit-box-sizing: border-box;
                min-height: 200px;
                box-shadow: 0px 2px 0px 0px #1f1f1f1a;

                .header {
                    padding: 20px 0;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    background-color: #f2f2f2;
                    border-bottom: 1px solid #f2f2f2;

                    h2 {
                        text-align: center;
                        margin-bottom: 0;
                        font-size: 24px;
                        text-transform: uppercase;
                        font-weight: 700;
                    }

                    p {
                        margin: 0;
                        padding: 0;
                        text-align: center;
                        font-size: 12px;
                        color: #ccc;
                        line-height: 14px;
                    }
                }

                .priceText {
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    background-color: #f6f6f6;
                    color: $color-light-grey;
                    padding: 30px 35px 20px 35px;

                    #doller {
                        font-size: 48px;
                        line-height: 46px;
                        font-weight: 100;
                    }

                    #price {
                        font-size: 80px;
                        font-weight: 300;
                        line-height: 58px;
                        margin-left: 3px;
                        padding-bottom: 0;
                    }

                    #misc {
                        margin-left: 1.5rem;
                        font-size: 14px;
                        line-height: 14px;
                    }
                }

                .features {
                    ul {
                        width: 100%;
                        list-style: none;
                        display: flex;
                        padding: 0;
                        margin: 0;
                        flex-direction: column;

                        li {
                            width: 100%;
                            display: flex;
                            box-sizing: border-box;
                            -webkit-box-sizing: border-box;
                            padding: 6px 33px;
                            border-top: 1px solid #f2f2f2;
                            justify-content: space-between;

                            >span {
                                color: #959595;
                                margin: 0;
                                font-size: 14px;
                                text-align: left;
                            }
                        }
                    }
                }

                .btnContainer {
                    width: 67%;
                    margin: 3rem auto;

                    button {
                        background-color: #ebebeb;
                        margin: 5px auto;
                        display: table;
                        float: none;
                        border-radius: 5px;
                        padding: 12px 40px;
                        color: $color-light-grey;
                    }
                }

            }

            @include for-size(phone-only) {
                width: 100%;
            }

        }

    }

    @include for-size(phone-only) {

        .pricingContainer {
            padding: 18% 4%;

            h2 {
                font-size: 40px;
                text-align: center;
            }

            p {
                font-size: 20px;
                line-height: 26px;
                text-align: center;
                padding-right: 0;
            }

            .plans {
                grid-gap: 70px;
            }
        }
    }

}