@import "../../../../../scss/mixins";
@import "../../../../../scss/variables";

.container{
  padding: 30px;
  display: flex;
  flex-direction: column;

  .header{
    h5{
      font-weight: 500;
      font-size: 20px;
      text-align: center;
      margin-bottom: 0;
      color: #1e2227;
    }
    p{
      font-size: 12px;
      text-align: center;
      font-style: italic;
      color: #1e2227;
      font-weight: 300;
    }
  }
  .refCopy{
    cursor: pointer;
  }
  > button{
    margin: 0 auto;
    width: 80%;
    font-size: 18px;
    border: 1px solid $color-primary-green;
    background-color: $color-primary-green;
    padding: 8px 12px;
    color: $color-white;
    transition: .2s;
    border-radius: 20px;
  }

  .share{
    padding: 2rem 0;
    p{
      font-size: 14px;
      text-align: center;
      color: #1e2227;
      font-weight: 300;
      margin: 0;
    }
    .icons{
      display: flex;
      flex-wrap: wrap;
      button {
        padding: 10px !important;
      }
      .emailContact{
        padding-top: 6px;
        > button{
          background-color: $color-white;
          border: 1px solid $color-primary-green;
          min-width: 64px;
          color: $color-primary-green;
          box-sizing: border-box;
          font-weight: 500;
          line-height: 1.75;
          border-radius: 4px;
          letter-spacing: 0.02857em;
          text-transform: uppercase;
          font-size: 20px;
          padding-top: 10px;
          > svg{
            margin-bottom: 5px;
          }
          &:hover{
            background-color: $color-primary-green;
            color: $color-white;
          }
        }
      }
      @include for-size(phone-only){
        button{
          padding: 10px 5px !important;
        }
        .emailContact{
          flex: 1;
          display: flex;
          justify-content: center;
          > button{
            font-size: 14px;
            padding: 5px 10px !important;
          }
        }
      }
    }
  }

  .stats{
    margin-top: 30px;
    h5{
      font-weight: 500;
      font-size: 20px;
      text-align: center;
      margin-bottom: 0;
      color: #1e2227;
    }
    .grid{
      display: flex;
      flex-wrap: wrap;
      padding: 3rem 0;
      flex: 1;
      .item{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 15px;
        flex-direction: column;
        span{
          color: #182628;
          font-size: 3em;
          font-weight: 600;
        }
        p{
          margin-top: 10px;
          margin-bottom: 0;
          color: #182628;
          text-align: center;
          font-weight: 400;
          font-size: 1.4em;
        }
      }
    }
  }
  .guide{
    > h3{
      font-size: 16px;
      font-weight: 600;
      color: $color-black;
      margin-bottom: 10px;
    }
    > p{
      font-size: 14px;
      font-weight: 300;
      color: $color-black;
    }
  }

  @include for-size(tablet-landscape-up){
    padding: 0 30px;
    > button{
      width: 52%;
    }

  }

  @include for-size(phone-only){
    padding: 30px 15px;
    > button{
      width: 100%;
      font-size: 16px;
    }
  }

}
