@import "../../../../scss/mixins";
@import "../../../../scss/variables";

.homeServices {
  padding: 5%;
  background-color: $color-white;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
  min-width: 320px;
  max-width: 1200px;

  h2 {
    font-size: 46px;
    line-height: 41px;
    font-weight: 300;
    margin-bottom: 10px;
  }

  >p {
    font-size: 24px;
    line-height: 28px;
    max-width: 720px;
    font-weight: 300;
    margin-bottom: 40px;
  }

  .gridContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 4rem 0 2.5rem 0;
    flex: 1;

    .card {
      display: flex;
      flex: 1 1 33.3%;
      margin-bottom: 3rem;

      .left {
        flex: 1;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $color-primary-black;
          border-radius: 50%;
          width: 70px;
          height: 70px;

          img {
            width: 30px;
            height: 30px;
            object-fit: contain;
          }
        }
      }

      .right {
        flex: 5;
        padding-left: 20px;
        padding-right: 20px;

        h5 {
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
        }

        p {
          font-size: 14px;
          line-height: 23px;
          font-weight: 500;
          margin-bottom: 10px;
        }

        span {
          font-size: 14px;
          color: $color-primary-green;
        }
      }

    }

    @include for-size(phone-only) {
      flex-direction: column;

      .card {
        margin-bottom: 8rem;

        .right {
          p {
            margin-bottom: 0;
          }
        }
      }

    }

  }

  @include for-size(phone-only) {
    padding-top: 5rem;

    h2 {
      font-size: 40px;
      text-align: center;
    }

    >p {
      padding-top: 1rem;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
    }
  }

}