@import "src/scss/mixins";
@import "src/scss/variables";

.container{
  padding: 2% 5%;
  margin-bottom: 10%;
  h1{
    font-size: 28px;
    margin-top: 10px;
    display: inline-block;
    color: #546E7A;
    margin-bottom: 15px;
  }
  p{
    font-size: 18px;
    color: #546E7A;
    margin-bottom: 30px;
  }
  .installationContainer{
    > h3{
      font-size: 22px;
      color: #546E7A;
      margin-bottom: 15px;
    }
    .step{
      display: flex;
      width: 100%;
      margin-bottom: 10px;
      flex: 1;
      > span{
        flex: 1;
        display: inline-block;
        font-size: 18px;
        color: #546E7A;
        margin-right: 15px;
        font-weight:  500;
      }
      > div{
        flex: 4;
        span{
          font-size: 18px;
          color: #546E7A;
          display: inline-block;
          margin-bottom: 8px;
          > a, button{
            font-size: 14px;
            background-color: rgb(200,200,200);
            padding: 5px;
            text-decoration: none;
            color: black;
            margin-left: 5px;
            border: none;
          }
        }
      }
    }
  }

  @include for-size(tablet-landscape-up){
    .installationContainer{
      .step{
        width: 60%;
      }
    }
  }

}
