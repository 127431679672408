@import "../../../../../scss/mixins";
@import "../../../../../scss/variables";

@keyframes slideIn {
  0%{
    height: 0;
    opacity: 1;
  }
  100%{
    height: min-content;
    opacity: 1;
  }
}

@keyframes slideOut {
  0%{
    height: min-content;
    opacity: 1;
  }
  100%{
    height: 0;
    opacity: 1;
  }
}

.dashBoardContainer{
  width: 100%;
  display: flex;
  padding: 1rem;
  flex: 1;
  min-height: 500px;
  .left {
    flex: 2;
    padding: 2rem 1.5rem;
    min-width: 360px;
  }

  .right{
    flex: 5;
    padding: 2rem 1.5rem;

    .header{
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      justify-content: space-between;
      .actions{
        display: flex;
        flex: 1;
        button {
          margin: 5px 10px;
          height: 35px;
          color: #fff;
          font-weight: 500;
          transition: .2s;
          text-align: center;
          padding: .375rem .75rem;
          font-size: 14px;
          line-height: 1.5;
          border-radius: .25rem;
        }
      }
      .searchBar{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 1;
        input {
          width: 80%;
          float: right;
          background: #e5e5e5;
          outline: none;
          border: 1px solid #f0f0f0;
          box-sizing: border-box;
          border-radius: 1rem;
          height: 33px;
          margin: 4px 10px 4px 0;
          padding: 0 20px;
          font-size: 14px;
        }
      }

      @include for-size(phone-only){
        flex-direction: column;

        .searchBar input{
          width: 100%;
          margin: 10px
        }

      }

    }

    .gridContainer{
      display: flex;
      flex-wrap: wrap;
      flex: 1;

      .col{
        flex: 1;
        padding: 1rem;
        min-height: 150px;
      }

      @media screen and (max-width: 1199.98px) {
           .col{
             flex: 0 0 50%;
             max-width: 50%;
           }
      }

      @media screen and (max-width: 768px){
        .col{
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      @include for-size(phone-only){
        .col{
          padding: 1rem 0;
        }
      }

    }

  }

  @include for-size(phone-only){
     flex-direction: column;
     .left{
       flex: 1;
       width: 100%;
       min-width: unset;
     }
     .right{
       flex: 1;
       width: 100%;
     }
  }

}
