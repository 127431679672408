@import "../../../../../scss/mixins";
@import "../../../../../scss/variables";

.privacyContainer{
  min-height: 500px;
}

.modalContainer{
  padding: 20px 0;
}

.emailOtp{
  h4{
    color: $color-black;
    font-weight: 400;
    padding: 0 20px;
  }

  .body{
    padding: 1.5rem 0 2.5rem 0;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
  }

  .footer{
    display: flex;
    justify-content: flex-end;

    .btn{
      float: right;
      margin: 15px 10px 0 0;
      width: 25%;
    }

  }

  .codeBtn{
    width: 30%;
    margin-left: 20px;
  }

  .codeWrapper{
    padding: 0px 20px;

    p{
      margin: 0;
      padding: 0;
      font-size: 14px;
      color: $color-black;
      margin-bottom: 10px;

      &#note{
        margin-top: 10px;
      }

    }

  }

}
