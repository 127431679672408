@import "../../../scss/mixins";
@import "../../../scss/variables";

.footerWrapper{
  padding: 7rem;
  background-color: $color-primary-black;
  min-height: 300px;

  .contactInput {
    width: 100%;
    padding: 15px 12px;
    border: 1px solid #F1F2F6;
    font-size: 14px;
    line-height: 20px;
    border-radius: 4px;
    outline: none;
  }

  .innerContainer{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 15px;
    margin: 0 auto;
    box-sizing: border-box;
    overflow: auto;
    min-width: 320px;
    max-width: 1200px;

    h3{
      color: $color-white;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .about {
      p {
        color: #A0A0A0;
        font-size: 14px;
        margin-top: 10px;
        line-height: 23px;
      }
    }

    .blogPosts{
       ul{
         list-style: none;
         padding: 0;
         li{
           position: relative;
           align-items: flex-start;
           display: flex;
           margin-bottom: 15px;
           padding-bottom: 10px;
           border-bottom: 1px solid #3C3C3C;
           img {
             margin-right: 5px;
           }
           span{
             margin: 2px 0 10px 0;
             font-size: 14px;
             line-height: 20px;
             font-weight: 400;
             color: #959595;
           }
           span.date{
             font-size: 11px;
             color: #5E5E5E;
             padding-left: 5px;
           }
         }

       }
    }

    .tags{
       .list{
         display: flex;
         flex-wrap: wrap;

         span{
           color: #A0A0A0;
           font-size: 14px;
           background: #2d2d2d;
           display: inline-block;
           padding: 5px 15px;
           margin-bottom: 10px;
           margin-right: 10px;
         }

       }
    }

    .contact{
      form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        input {
          padding: 12px 16px;
        }

        button {
          width: 100%;
          border: none;
          outline: none;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #a3c93a;
          color: #ffffff;
          padding: 10px 25px;
          font-size: 14px;
          line-height: 22px;
          border-radius: 4px;
        }
      }

      .item{
        display: flex;
        align-items: flex-start;
        border-bottom: 1px solid #3C3C3C;
        padding-bottom: 20px;
        margin-bottom: 15px;
        img {
          margin-right: 10px;
          margin-top: 4px;
        }
        span{
          color: #bbb;
          font-size: 14px;
        }
      }
    }

    @include for-size(phone-only){
      grid-template-columns: 1fr;
      grid-row-gap: 30px;
    }

  }

  @include for-size(phone-only){
    padding: 2rem;
  }

}