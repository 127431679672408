@import "../../../../../scss/mixins";
@import "../../../../../scss/variables";

.offerContainer {
	min-height: 500px;
	padding: 1rem 0;

	h4 {
		text-align: center;
		color: $color-black;
	}

	.offers {
		.offerCard {
			.imageContainer {
				height: 100%;

				img {
					width: 100%;
					height: 400px;
					background-repeat: no-repeat;
					background-attachment: fixed;
					background-position: center;
				}
			}


			.offerTitle {
				margin-top: 20px;
				font-size: large;
			}

			.offerDescription {
				font-size: medium;
			}
		}
	}
}
