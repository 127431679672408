@import "../../../../../scss/mixins";
@import "../../../../../scss/variables";

.container{
  padding: 30px;

  .empty{
    display: flex;
    justify-content: center;
    align-items: center;
    > span{
     font-size: 2rem;
     color: $color-black;
      @include for-size(phone-only){
        font-size: 1.5rem;
      }
    }
    > a{
      border: 1px solid $color-primary-green;
      padding: 2px 10px;
      font-size: 1.2rem;
      letter-spacing: 0.4px;
      border-radius: 5px;
      color: $color-primary-green;
      margin-left: 5px;
      &:hover{
        color: $color-white;
        background-color: $color-primary-green;
      }
      @include for-size(phone-only){
        font-size: 1rem;
      }
    }
  }

  .main{
    padding-bottom: 50px;
    .top{
      display: flex;
      flex: 1;
      .chart{
        flex: 1;
      }
      .labels{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        ul{
          padding-left: 4rem;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          margin: 0;
          list-style: none;
          li{
            padding: 5px;
            text-transform: capitalize;
            font-size: 16px;
          }
        }
      }
    }

    .table{
      > table{
        margin-top: 3em;
        table-layout: fixed;
        width: 98%;
      }
      > table > thead{
        background-color: gray;
      }
      th{
        text-align: left;
        color: white;
        font-size: 20px;
        padding: 7px;
        @include for-size(phone-only){
          font-size: 14px;
        }
      }
      td{
        font-size: 16px;
        padding-left: 7px;
        padding-top: 5px;
        @include for-size(phone-only){
          font-size: 14px;
        }
      }
    }

  }

  @include for-size(tablet-landscape-up){
    padding: 0 30px;
  }

  @include for-size(phone-only){
    padding: 30px 15px;
  }

}
