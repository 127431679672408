@import "../../../../scss/mixins";
@import "../../../../scss/variables";

.sliderContainer{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  > h1{
    color: white !important;
  }

  .sliderContainerOne{
    //padding: 7% 4%;
    height: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    position: relative;

    > .desktopImage{
      width: 100%;
      height: 100%;
      display: block;
      @include for-size(phone-only){
        display: none;
      }
    }

    > .mobileImage{
      width: 100%;
      height: 100%;
      display: none;
      @include for-size(phone-only){
        display: block;
      }
    }

    .card{
       background-color: rgba(0, 0, 0, 0.245);
       width: 100%;
       max-width: 50.7vw;
       padding: 5%;
       position: absolute;
       right: 20px;
       margin-left: 5%;
       h1{
         color: white;
         font-size: 4.06vw;
         text-align: center;
         line-height: 5.06vw;
       }
       p{
        margin-top: 3.5rem;
        color: white;
        font-size: 1.4vw;
        line-height: 23px;
      }
      &.leftCard{
        left: 0;
      }
      @include for-size(phone-only){
        display: none;
        bottom: -25px;
        background-color: $color-primary-green;
        z-index: 1001;
        right: unset;
        margin: 0;
        max-width: 73%;
      }
    }

    @include for-size(phone-only){
      align-items: center;
    }

  }

  @include for-size(phone-only){
    .sliderContainerOne{
      justify-content: center;
      padding-left: 0;
      padding-right: 0;

      .card{
        h1{
          font-size: 1.5rem;
        }

        p{
          font-size: 0.59rem;
          margin: 0;
          line-height: 8px;
        }
      }

    }
  }

}
