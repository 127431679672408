@import "../../../scss/mixins";
@import "../../../scss/variables";

.dashBoardWrapper{
  .navBar{
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    background-color:$color-primary-green;

    ul {
      display: flex;
      list-style: none;
      margin: 0;
      li {
        padding: 1rem;
        cursor: pointer;
        margin-right: 20px;
        a {
          color: $color-white;
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 0.5px;
          line-height: 17px;
        }
        &.active a {
          background-color: $color-dark-grey;
          padding: 1rem;
          border-radius: 10px;
        }
      }
      @include for-size(phone-only){
        padding: 0;
        li{
          margin-right: 10px;
          padding: 1rem 0.5rem;
          a {
            font-size: 14px;
            letter-spacing: 0;
          }
        }
      }
    }
    > div{
      display: flex;
      align-items: center;
      span{
        font-size: 18px;
        color: $color-white;
      }
      @include for-size(phone-only){
        display: none;
      }
    }

  }

  .container{
    margin: 0 auto;
    box-sizing: border-box;
    overflow: auto;
    min-width: 320px;
    max-width: 1250px;
  }

}
