@import "../../../scss/mixins";
@import "../../../scss/variables";

.buttonContainer{
  width: 100%;
  border: none;
  outline: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-primary-green;
  color: $color-white;
  padding: 10px 25px;
  font-size: 18px;
  line-height: 22px;
  border-radius: 4px;

  &.delete{
    background-color: red;
    width: 40%;
  }

  &.loading:after{
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    pointer-events: none;
    background-color: rgba(255,255,255, 0.5);
  }

  &.small{
    font-size: 15px;
    padding: 5px;
  }

  &.danger{
    background-color: red;
  }

}
