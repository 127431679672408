@import "./variables";

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: $phone-only) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: $tablet-portrait-up) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: $tablet-landscape-up) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: $desktop-up) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: $big-desktop-up) {
      @content;
    }
  }
}