@import "../../../scss/mixins";
@import "../../../scss/variables";

.aboutUsPage{
  .main{
    padding: 6%;

    .heading{
      font-weight: 300;
      h2{
        font-size: 46px;
        line-height: 41px;
        margin-bottom: 25px;
      }
      p{
        font-size: 24px;
        line-height: 28px;
        color: $color-light-grey;
        margin-bottom: 15px;
      }
    }

    .body{
      padding: 4% 0;
      display: flex;
      flex: 1;

      .left{
        flex: 4;
        min-height: 300px;
        background-color: $color-light-grey-1;
        > img{
          width: 100%;
          height: 100%;
        }
      }

      .right{
        flex:3;
        padding: 0 5%;
        h4{
          font-size: 24px;
          line-height: 28px;
          font-weight: 600;
          margin-bottom: 25px;
          color: $color-dark-grey;
        }

        > p{
          padding-bottom: 15px;
          font-size: 14px;
          line-height: 23px;
        }

        .highlight{
          padding-left: 25px;
          margin-left: 70px;
          margin-top: 30px;
          margin-bottom: 50px;
          border-left: 3px solid $color-primary-green;
          > p{
            font-style: italic;
            font-size: 22px;
            line-height: 30px;
            padding-bottom: 0;
            font-weight: 300;
          }
        }

      }

    }

    .gridContainer{
      padding-top: 2%;
      display: flex;
      flex: 1;
      .item {
        padding: 0 1.5rem;
        flex: 1;
        h3{
          font-weight: 600;
          color: $color-dark-grey;
          margin-bottom: 10px;
          text-align: center;
        }
        p{
          font-size: 14px;
          text-align: center;
        }
      }
    }

  }

  @include for-size(phone-only){
    .main{

      .heading{
        h2{
          font-size: 40px;
          text-align: center;
        }
        p{
          font-size: 20px;
          line-height: 26px;
          text-align: center;
        }
      }

      .body{
        flex-direction: column;

        .left{
          margin-bottom: 2rem;
        }

        .right{
          padding: 0;
        }

      }

      .gridContainer{
        flex-direction: column;
        .item {
          padding: 0;
        }
      }

    }
  }

}
