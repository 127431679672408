@import "../../../scss/mixins";
@import "../../../scss/variables";

.innerContainer{
    min-width: 500px;

    > .close {
      display: flex;
      position: relative;
      width: 22px;
      height: 22px;
      margin-left: auto;
      user-select: none;
      cursor: pointer;
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

}