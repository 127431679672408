@import "../../../../scss/mixins";
@import "../../../../scss/variables";

@keyframes slideIn {
  0%{
    left: -288px;
  }
  100%{
    left: 0;
  }
}

@keyframes slideOut {
  0%{
    left: 0;
  }
  100%{
    left: -288px;
  }
}

.sideBarContainer{
   position: fixed;
   left: 0;
   top: 0;
   width: 100%;
   height: 100vh;

   .placeholder{
     position: fixed;
     left: 0;
     top: 0;
     z-index: -1;
     width: 100%;
     background: rgba(0, 0, 0, 0.234);
     height: 100%;
   }

   .innerContainer{
     width: 80%;
     position: relative;
     height: 100vh;
     background-color: $color-white;
     padding: 1rem;
     animation: slideIn 0.6s ease-in-out;
   }

   .header{
     display: flex;
     justify-content: flex-end;
   }

  &.slideOutSideBar{
    .innerContainer{
      animation: slideOut 0.6s ease-in-out;
    }
  }

}
