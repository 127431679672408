@import "../../../scss/mixins";
@import "../../../scss/variables";

.servicesPage{
   .main{
     padding: 6%;

     .heading{
       font-weight: 300;
       h2{
         font-size: 46px;
         line-height: 41px;
         margin-bottom: 25px;
         font-weight: 300;
       }
       p{
         font-size: 24px;
         line-height: 28px;
         color: $color-light-grey;
         margin-bottom: 15px;
       }
     }

     .services{
       display: flex;
       flex-wrap: wrap;
       padding: 4rem 0 2.5rem 0;
       flex: 1;

       .card{
         display: flex;
         flex-direction: column;
         flex: 1 0 33.3%;
         margin-bottom: 50px;

         .top{
           flex: 1;
           padding-bottom: 2rem;
           div {
             display: flex;
             justify-content: center;
             align-items: center;
             background-color: $color-primary-black;
             border-radius: 50%;
             width: 70px;
             height: 70px;
             img {
               width: 30px;
               height: 30px;
               object-fit: contain;
             }
           }
         }

         .bottom{
           flex: 5;
           h5{
             font-weight: 600;
             font-size: 18px;
             line-height: 24px;
           }
           p{
             font-size: 14px;
             line-height: 23px;
             font-weight: 500;
             margin-bottom: 10px;
           }
           span{
             color: $color-primary-green;
           }
         }

       }

       @include for-size(phone-only){
         flex-direction: column;

         .card{
           margin-bottom: 8rem;
           .right {
             p{
               margin-bottom: 0;
             }
           }
         }

       }

     }

   }
  @include for-size(phone-only){
    .main{

      .heading{
        h2{
          font-size: 40px;
          text-align: center;
        }
        p{
          font-size: 20px;
          line-height: 26px;
          text-align: center;
        }
      }

      .services{
        .card{
         margin-bottom: 20px;
        }

      }
    }
  }
}
