@import "../../scss/mixins";
@import "../../scss/variables";

.registerWrapper {
  padding: 3%;
  font-size: 16px;
  max-height: 600px;
  overflow: auto;

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .registerCaption {
    color: #abaab4;

    a {
      color: #49475b !important;
      font-weight: 600;
      cursor: pointer;
      transition: cubic-bezier(0.39, 0.575, 0.565, 1);
    }

    a:hover {
      color: #2f2d3b !important;
    }
  }

  // h2{
  //   text-align: center;
  //   color: $color-dark-grey;
  //   font-weight: 400;
  // }

  p {
    text-align: center;

    > span {
      color: $color-black;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 35px;

  p {
    // font-size: 14px;
    text-align: right;
  }

  label {
    margin-bottom: 7px;
    font-size: 15px;
  }

  .formInput {
    width: 100%;
    padding: 15px 20px;
    border: 1px solid #c5c5c5;
    font-size: 14px;
    line-height: 20px;
    outline: none;
    border-radius: 4px;
    position: relative;
    transition: 0.1s;

    input,
    select {
      background: none;
      border: none;
      outline: none;
      width: 85%;
      padding: 0;
      margin-left: 15px;
    }

    .rightBorder {
      border-right: 1px solid #dcdcdc;
      display: inline;
      margin-left: 35px;
    }
  }

  .btnContainer {
    // width: 100%;
    // margin-top: 25px;

    button {
      border-radius: 9px;
      padding: 13px;
    }
  }

  .error {
    color: red;
    padding: 0;
    margin-bottom: 0;
  }
}

::placeholder {
  color: #abaab4;
  font-size: 16px;
}

.svgDiv {
  top: 0;
  height: 100%;
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;

  svg {
    font-size: 22px;
    fill: #818087;
  }
}

.formInput:hover {
  border: 1px solid #9c9ba5;
}

.formInput:focus-within {
  border: 1px solid #524f65;
}

.forgotCaption {
  text-align: right !important;
  margin-top: 10px;

  span {
    color: #49475b !important;
    word-spacing: 1px;
    letter-spacing: 0.1px;
    font-size: 15px;
    text-align: right;
  }
}

.formBottom {
  margin-top: 29px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.formGroup {
  display: flex;
  gap: 23px;
}

.loginWrapper {
  overflow: hidden;
  .wave {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 100%;
    z-index: -1;
  }
  .container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 7rem;
    padding: 0 2rem;
  }
  .img {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .loginContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .img img {
    width: 500px;
  }

  form {
    width: 360px;
  }

  .loginContent img {
    height: 100px;
  }

  .loginContent h2 {
    margin: 15px 0;
    color: #333;
    text-transform: uppercase;
    font-size: 2.9rem;
  }

  a {
    display: block;
    text-align: right;
    text-decoration: none;
    color: #999;
    font-size: 0.9rem;
    transition: 0.3s;
  }

  a:hover {
    color: #00c853;
  }

  .btn {
    display: block;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    outline: none;
    border: none;
    background-image: linear-gradient(to right, #00e676, #00c853, #00e676);
    background-size: 200%;
    font-size: 1.2rem;
    color: #fff;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    margin: 1rem 0;
    cursor: pointer;
    transition: 0.5s;
  }
  .btn:hover {
    background-position: right;
  }

  @media screen and (max-width: 1050px) {
    .container {
      grid-gap: 5rem;
    }
  }

  @media screen and (max-width: 1000px) {
    form {
      width: 290px;
    }

    .loginContent h2 {
      font-size: 2.4rem;
      margin: 8px 0;
    }

    .img img {
      width: 400px;
    }
  }

  @media screen and (max-width: 900px) {
    .container {
      grid-template-columns: 1fr;
    }

    .img {
      display: none;
    }

    .wave {
      display: none;
    }

    .loginContent {
      justify-content: center;
    }
  }
}
